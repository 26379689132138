/****  LAYOUT                   ****/

/**    One Column Layout (Authentication/Setup Landing    **/

.three_col_inner {
  display: flex;
  flex-direction: row;
  gap: 28px;
  padding: 1rem 2rem;

  // Main section
  > div:first-child {
    flex-grow: 1;

    .sidebar {
      max-width: 290px;
      flex: 0 0 290px;
      margin-right: 1rem;
      > * {
        margin-top: 1rem;
      }
    }
  }
}

#upcoming-leagues-page {
  .league_sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

#dashboard-page {
  .dashboard_inner {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

#league-details-page {
  &.three_col_inner {
    display: flex;
    flex-direction: column;
    padding: 0;

    > .league-details {
      margin: 1rem 1rem 0 1rem;
    }

    > .league-detail-wrapper {
      margin: 0 1rem;
    }
  }
}
