/****  FORM ELEMENTS            ****/

/****  Alerts      ****/

.alert {
  position: relative;
  padding: 1rem 4rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &::before {
    position: absolute;
    top: 0.65rem;
    left: 1.2rem;
    content: "\f071";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    width: 30px;
    height: 30px;
    font-size: 25px;
    color: #fff;
    line-height: 1;
    text-align: center;
    color: #fff;
  }

  span {
    h2 {
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
    }

    p {
      margin: 0.5rem 0 0 !important;
    }

    a {
      color: #fff;
    }
  }

  &.alert-success {
    background: $success;
    span {
      h2,
      p {
        color: $dark !important;
      }
    }
    &::before {
      content: "\f00c";
      color: $dark !important;
    }
  }

  &.alert-danger {
    background: $danger;
    border: 0;
    &::before {
      font-size: 22px;
      top: 0.9rem;
    }
  }

  &.alert-info {
    background: rgba($blue, 0.22);
    border-color: $blue;
  }
}

main.authentication .alert {
  &.alert-danger {
    background: $red;
  }

  &.alert-success {
    background: $success;
  }
}

@media (min-width: 801px) {
  main.authentication .alert {
    padding: 1rem 5.625rem;

    &::before {
      left: 1.9rem;
    }
  }
}

@media (max-width: 500px) {
  main.authentication .alert {
    padding-right: 2rem;
  }
}

/****  Form Group       ****/

.form-group {
  position: relative;
  margin-bottom: 1.5rem;

  label.top_label {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0.75rem;
  }

  input ~ label {
    position: absolute;
    top: 1.1rem;
    left: 0.9rem;
    color: rgba(#fff, 0.4);
    transition: all 0.25s ease !important;
    text-transform: uppercase;
  }

  input ~ p,
  div ~ p {
    line-height: 1.1;
    margin-top: 0.5rem;
    opacity: 0.56;
  }

  .row input ~ label {
    left: 1.1rem;
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    top: -0.7rem;
    color: #fff;
    background: $panelBG;
    font-size: 0.7rem;
    padding: 5px 8px;
  }

  input:not(:placeholder-shown) {
    border: 2px solid #fff;
    background: none;
  }

  .field-validation-valid {
    color: $red;
    font-size: 13px;
    font-weight: 500;
    margin-top: 0.25rem;
  }

  &.password_strength {
    span.hint {
      margin-top: 0.25rem;
      font-size: 13px;
      display: none;
    }

    input:focus ~ span.hint {
      display: block;
    }

    .badge {
      display: none;
    }

    input:focus ~ .badge {
      position: absolute;
      top: 1.2rem;
      right: 1rem;
      border-radius: 0;
      display: block;
    }
  }

  .checkbox {
    position: relative;

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"] ~ label::before {
      width: 22px;
      height: 25px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;
      display: block;
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      content: "";
      text-align: center;
      font-size: 16px;
      border: 2px solid transparent;
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      line-height: 1.3;
      color: #fff;
    }

    input[type="checkbox"]:checked ~ label::before {
      content: "\f00c";
      border: 2px solid #fff;
    }

    label {
      color: #fff;
      font-size: 13px !important;
      position: relative;
      top: unset !important;
      left: unset !important;
      padding-left: 2.5rem !important;
      background: none !important;
      line-height: 1.5;
      margin-bottom: 0;

      a {
        font-size: 13px;
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}
.details_container {
  align-items: center;
  display: flex;
  p {
    margin: 0;
    line-height: 58px;
  }
}

main.authentication.login .form-group {
  margin-bottom: 2rem;
}

.form-control {
  background: rgba(#ffffff, 0.1);
  border: 2px solid transparent;
  border-radius: 4px;
  color: #fff;
  transition: all 0.25s ease !important;

  &.form-control-lg {
    font-size: 1rem;
    height: 58px;
  }

  &:focus {
    background: none;
    border: 2px solid #fff;
    box-shadow: none;
    color: #fff;
  }

  &.error {
    border: 2px solid $red;
  }

  &[type="number"] {
    background: none;
    border: 2px solid #fff;
    box-shadow: none;
    color: #fff;
  }
}

/****  Balance Container     ****/
#upcoming-leagues-page,
#my-bets-page {
  .balance_container {
    display: flex;
    flex-direction: row;
    .balance,
    .promo {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 1rem 1.25rem 0.7rem;
      text-align: center;
      border-radius: 43px 0px 0px 43px;
      background: #363b56;
      span:first-child {
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        opacity: 0.514;
        color: #fff;
      }

      .amount {
        color: #fff;
        font-size: 26px;
        font-weight: 500;
        display: block;
      }
    }

    .promo {
      border-radius: 0px 43px 43px 0px;
      border: 1px solid #363b56;
      background: #161928;
      border: 1px solid $panelBorder;
      span,
      span:first-child {
        color: #fff;
      }
    }
  }
}

#sidebar {
  .balance_container {
    display: flex;
    flex-direction: row;
    .balance,
    .promo {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 1rem 1.25rem 0.7rem;
      text-align: center;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      background: rgba(255, 255, 255, 0.1);
      span:first-child {
        font-size: 13px;
        color: #fff;
        letter-spacing: 0;
        text-transform: uppercase;
        text-align: center;
        display: block;
        font-weight: 700;
      }

      .amount {
        font-size: 26px;
        text-align: center;
        display: block;
        font-weight: 300;
        color: #fff;
      }
    }

    .promo {
      background: #161928;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border: 1px solid $panelBorder;
      span,
      span:first-child {
        color: #fff;
      }
    }
  }
}

/****  Balance Container     ****/

.addedCards {
  list-style-type: none;
  margin: 1rem 0 2rem;
  padding: 0;
  li {
    padding: 0.75rem 0.75rem;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-top: 1px solid $panelBorder;
    align-items: center;

    i {
      color: #5d5d5d;
      font-size: 22px;
      margin-right: 1.25rem;
      line-height: 31px;
    }

    span.number {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }

    .status {
      margin-left: auto;
      font-size: 14px;
      min-height: 31px;
      display: flex;
      align-items: center;

      input {
        display: none;
      }

      input ~ label {
        border-radius: 3px;
        content: "Selected";
        padding: 0.25rem;
        background: none;
        border: 1px solid $red;
        color: $red;
        cursor: pointer;
        margin: 0;
      }

      input:checked ~ label {
        border-radius: 3px;
        content: "Selected";
        padding: 0.25rem;
        color: #fff;
        background: $red;
        border: 1px solid $red;
      }
    }

    &:first-child {
      border-radius: 6px 6px 0 0;
      border-top: 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &.expired {
      background: $panelBorder;

      i {
        color: #fff;
        opacity: 0.6;
      }

      span.number {
        color: rgba(#fff, 0.45);
      }

      .status span {
        color: rgba(#fff, 0.45);
      }
    }
  }
}

/**   Card      **/

.card {
  background: #191d2c;
  border-radius: 6px;
  border: 1px solid #272b41;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

  .card-footer {
    background: #141724;
    border-top: 1px solid #272b41;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    span {
      color: #fff;
      font-size: 13px;
    }
  }
}

/**   Breadcrumb     **/

.breadcrumb {
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0 0 2rem;
    li {
      margin-right: 3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 0.25;

      &.active {
        opacity: 1;
      }
      .counter {
        max-width: 40px;
        flex: 0 0 40px;
        height: 40px;
        border-radius: 50%;
        display: block;
        text-align: center;
        line-height: 2;
        background: $highlightBlue;
        color: #fff;
        font-size: 20px;
        margin-right: 1rem;
      }

      .counter ~ span {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}

/****  TABLE ELEMENTS            ****/

/****  Thead      ****/

.table_filters form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: 100%;
  margin-bottom: 1.25rem;

  > * {
    margin-right: 1rem;

    &.form-group {
      margin-bottom: 0;
    }

    label {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .footer_btns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    a.btn-sm {
      padding: 0.45rem 1rem;
    }

    a + a {
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0.55rem 1rem;
      text-decoration: none;
      line-height: 1.5;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin-right: 0rem;

      &.form-group {
        margin-bottom: 0.5rem;
      }
    }
  }
}

table.table {
  margin-bottom: 0;
  border-spacing: 0px 5px;
  border-collapse: separate;

  tbody tr {
    border-spacing: 0px 5px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

    td {
      border-top: 1px solid $panelBorder;
      border-bottom: 1px solid $panelBorder;
      background: #2a2f45;
      min-height: 50px;
      padding: 12px 14px;
      color: #fff;

      &:first-child {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid $panelBorder;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid $panelBorder;
      }

      &.action {
        vertical-align: middle;
        padding: 0.5rem 0.75rem;
        text-align: right;
        a {
          i {
            color: $primary;
            font-size: 16px;
          }
        }
      }
    }
  }

  &.table-dark,
  &.table-light {
    background: none;
    th {
      background: none;
      text-transform: uppercase;
      color: #fff;
      border: 0;

      &:first-child {
        border-radius: 3px 0 0 0;
      }

      &:last-child {
        border-radius: 0 3px 0 0;
      }
    }

    td {
      color: #fff;
    }
  }

  &.table-sm {
    th,
    td {
      font-size: 13px;
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .pagination_navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    > * {
      width: 42px;
      height: 42px;
      display: block;
      border: 1px solid #edebeb;
      text-align: center;
      line-height: 2.6;
      font-size: 16px;

      @media (max-width: 1500px) {
        width: 32px;
        height: 32px;
        line-height: 2.4;
        font-size: 13px;
      }

      &.page_number {
        color: #37373c;
        border-left: 0;
        color: #fff;
        &.active {
          background: #fff;
          color: #111;
        }

        &:hover:not(.active) {
          text-decoration: none;
          background: rgba(#edebeb, 0.25);
        }

        &.first {
          border-radius: 4px 0 0 4px;
          border-left: 1px solid #edebeb;
          margin-left: 0.35rem;
        }

        &.last {
          border-radius: 0 4px 4px 0;
          margin-right: 0.35rem;
        }
      }

      &.navigation_link {
        border-radius: 4px;
        margin: 0 0.35rem;
        background: none;
        border: 1px solid #fff;
        color: #fff;
        font-size: 20px;
        line-height: 2.1;

        @media (max-width: 1500px) {
          font-size: 18px;
          line-height: 1.8;
        }

        &:disabled,
        &.disabled {
          opacity: 0.15;
        }
      }
    }
  }

  .no_of_results {
    position: absolute;
    top: 1.5rem;
    left: 0;
    padding-right: 1rem;
    color: #fff;
    max-width: calc((100% - 434px) / 2);
    flex: 0 0 calc((100% - 434px) / 2);

    @media (max-width: 1500px) {
      top: 1.35rem;
      max-width: calc((100% - 345px) / 2);
      flex: 0 0 calc((100% - 345px) / 2);
    }

    @media (max-width: 500px) {
      position: relative;
      top: unset;
      left: unset;
      max-width: 100%;
      flex: 0 0 100%;
      margin-top: 1rem;
    }

    .results {
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      font-weight: 500;

      @media (max-width: 1500px) {
        font-size: 12px;
      }
    }

    a {
      text-decoration: underline;
      i {
        margin-right: 0.5rem;
      }
    }
  }
}

/****  LEAGUE ELEMENTS            ****/

/****  How to enter     ****/

.entry_inner {
  .breadcrumb {
    li {
      margin-right: 1.5rem;
    }

    li:last-child {
      margin-right: 0;
    }
  }
}

/****  League Header     ****/

.entry_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem 0;

  h3 {
    font-size: 22px;
    margin: 0;
    font-weight: 600;
  }
}

.league_status {
  border: 1px solid $highlightBlue;
  padding: 0.25rem 0.75rem;
  font-size: 12px;
  color: $highlightBlue;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 20px;
}

/****  Sidebar Header     ****/

#sidebar {
  .balance_container {
    border-top: 3px solid $primary;
    border-radius: 4px;
    padding: 0.5rem 0;

    .balance,
    .promo {
      padding: 0.5rem 1.25rem;
      span:first-child {
        color: #fff;
      }
    }

    .promo {
      background: none;
      border-left: 1px solid rgba(#979797, 0.2);

      span {
        color: #fff;
      }
    }
  }

  .betslip_status,
  .league_points {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #000000;
    border-radius: 3px;
    margin-top: 1.5625rem;
    text-align: center;
    padding: 1.25rem 1rem;
  }

  .betslip_status {
    h6 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0.75rem;
    }

    span.total {
      font-size: 22px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 0.75rem;
      display: block;
    }
  }

  .league_points {
    h4 {
      margin-bottom: 1rem;
      font-size: 18px;
    }
  }
}

.betslip {
  h2 {
    font-weight: bold;
    font-size: 22px;
    color: #fff;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  h4 {
    font-weight: bold;
    opacity: 0.48;
    font-size: 12px;
    color: #fff;
    margin-bottom: 2rem;
  }

  .stake {
    h3 {
      color: #fff;
      margin-top: 2rem;
    }

    .btn_group {
      margin-left: -5px;
      margin-right: -5px;

      .form-group {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 0rem;
      }

      label {
        position: relative;
        left: 0;
        top: 0;
        border: 1px solid $highlightBlue;
        background: $highlightBlue;
        color: #fff;

        .amount {
          font-size: 15px;
          font-weight: 500;
          display: block;
          margin-bottom: 0.25;
        }

        .entries {
          font-size: 12px;
          font-weight: 400;
          opacity: 0.75;
          display: block;
        }
      }
    }
  }
}

.btn_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.75rem 0 2rem;
  div {
    input {
      display: none;
    }

    label {
      border: 1px solid #0b0b0b;
      border-radius: 3px;
      text-align: center;
      width: 100%;
      padding: 0.5rem 0;
      cursor: pointer;
    }

    input:checked ~ label {
      background: $primary;
      border-color: $primary;
      color: #fff;
    }
  }
}

.hexagon {
  position: relative;
  width: 44px;
  height: 25.4px;
  background-color: $primary;
  margin: 12.7px 0;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;
  left: 0;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 12.7px solid $primary;
}

.hexagon:after {
  top: 100%;
  width: 0;
  border-top: 12.7px solid $primary;
}

/**    Navigation icon         **/

.nav-icon {
  @media (min-width: 1200px) {
    display: none !important;
  }

  max-width: 42px;
  flex: 0 0 42px;
  height: 42px;
  position: relative;
  margin: 0 0.5rem 0 0;
  padding: 0.5rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 50%;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 18px;
    background: #fff;
    opacity: 1;
    left: 12px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      top: 12px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 18px;
    }

    &:nth-child(3) {
      top: 24px;
    }
  }
}

body.open {
  .nav-icon {
    span {
      background: #fff;
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 12px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 25px;
      }
    }
  }
}

#account_slideout {
  position: fixed;
  width: 325px;
  height: calc(100% - 60px);
  top: 60px;
  right: -325px;
  z-index: 20;
  background: #fff;
  transition: all 0.35s ease;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &.show {
    right: 0;
  }

  > *:not(.responsible-footer) {
    margin: 1.25rem 1.25rem 0;
  }
  .balance-container {
    .amount,
    .promo {
      span:first-child {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        display: block;
        text-align: center;
      }

      .amount {
        font-size: 23px;
        text-align: center;
        display: block;
      }
    }
  }

  .navigation {
    padding: 0;
    list-style-type: none;
    .nav-item {
      display: block;
      a {
        border-top: 1px solid #e8e8e8;
        padding: 1.25rem 0;
        color: #111;
        position: relative;
        display: block;
        i {
          position: absolute;
          top: 1.25rem;
          right: 0;
          color: #111;
        }
      }
      &:last-child {
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  .footer-CTA {
    flex-direction: row;
    justify-content: space-between;
    display: flex;

    .btn {
      width: 47%;
      i {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  .account-logout {
    margin: auto 1.25rem 1.5rem !important;
    border-top: 1px solid #e8e8e8;
    padding-top: 1.5rem;
    padding: 1.5rem calc(12% - 1.25rem) 0;
    text-align: center;
    span {
      font-size: 13px;
      margin-bottom: 1rem;
      display: block;
    }

    .btn {
      width: 100%;
    }
  }

  .responsible-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f9f9f9;
    padding: 5px 15px;
    font-size: 12px;
    width: 100%;
    a {
      color: #111;
      font-size: 12px;
    }
  }
}
