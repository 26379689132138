/****  AUTHENTICATION               ****/

/**   Authentication Form        **/

main.authentication {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  background: url("../../../../public/images/bg-dots.png") center center
      no-repeat,
    url("../../../../public/images/bg-soldier.png") right bottom no-repeat,
    #1b1e2e;
  position: relative;
  padding: 1rem;

  .logo {
    margin: calc((100vh - 620px) / 4 - 17px) 0;
    width: 300px;

    @media (max-height: 750px) {
      margin: 3rem 0;
    }
  }

  #login-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 86px;
      height: 51px;
      border-radius: 5px;
      background: $loginPanelButtonBG;
      padding: 0;

      // button text
      color: $white;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .btn-container a.text-link {
      font-size: 14px;
      font-weight: 400;
      text-decoration-line: underline;
    }

    .crossSell {
      font-size: 14px;
      font-weight: 400;

      a {
        color: $loginPanelCrossSellLinkColor;
      }
    }

    input {
      color-scheme: dark;
      width: 377px;
      height: 54px;
      border-radius: 4px;
      background: $loginPanelInputBG;

      &:not(:placeholder-shown) {
        border: none;
      }

      &:auto-fill {
        background-color: $loginPanelInputBG;
      }

      &:active,
      &:focus {
        border-radius: 5px;
        border: 1px solid $loginPanelCrossSellLinkColor;
        background: $loginPanelInputBG;
        box-shadow: 0px 0px 0px 4px rgba(35, 150, 213, 0.25);
      }
    }
  }

  h1 {
    margin-bottom: 2.5rem;
    text-align: center;
    font-size: 26px;
    letter-spacing: -1px;

    @media (max-height: 750px) {
      font-size: 50px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      margin-bottom: 2rem;
    }
  }

  p {
    font-size: 16px;
  }

  .form_container {
    background: $loginPanelBG;
    z-index: 200;
    position: relative;
    border: 1px solid $loginPanelBorder;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    width: 539px;
    height: 491px;

    .alert {
      border: 0;
      border-radius: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-bottom: 0;

      @media (min-width: 800px) {
        margin-bottom: -2.5rem;
      }

      @media (max-height: 800px) and (min-width: 800px) {
        margin-bottom: -1.5rem;
      }
    }

    @media (max-height: 750px) {
      width: 500px;
      margin-bottom: 3rem;

      form {
        padding: 3rem;
      }
    }

    @media (max-width: 800px) {
      width: 90%;

      form {
        padding: 2rem;
      }
    }

    @media (max-width: 500px) {
      form {
        padding: 2rem 1.25rem;
      }
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .crossSell {
      margin-top: 2rem;
      display: block;

      p {
        font-size: 14px;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  .copy {
    margin-top: 12px;
    font-size: 14px;
    color: $textLight;
  }
}

/**   Registration Form        **/

main.register {
  .registration_container {
    padding-top: 60px;

    h1 {
      margin: 2rem 0;
      text-align: center;
    }

    .registration_form {
      display: flex;
      flex-direction: row;
      margin-bottom: 4rem;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      border: 1px solid $panelBorder;

      > * {
        max-width: 55%;
        flex: 0 0 55%;
        padding: 4rem;

        h2 {
          margin-bottom: 1.5rem;
        }
      }

      main {
        background: $panelBG;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        .footer-btns {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }

        @media (max-width: 991px) {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 3rem;
        }

        @media (max-width: 500px) {
          padding: 1.25rem;
        }
      }

      aside {
        background: #1a1e2e;
        max-width: 45%;
        flex: 0 0 45%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        @media (max-width: 991px) {
          display: none;
        }

        .app-logos {
          margin-top: 1.5rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          a {
            img {
              max-height: 60px;
              max-width: 100%;
            }
          }
        }

        .block {
          border: 1px solid #3e3e3e;
          border-radius: 3px;
          margin-top: 1.5rem;

          h4 {
            font-size: 16px;
            font-weight: bold;
            padding: 1.25rem 1.25rem 0;
            margin-bottom: 0;
          }

          ul {
            margin: 0;
            padding: 0;
            li {
              display: flex;
              flex-direction: row;
              padding: 1rem 1.25rem 1rem 0;
              i {
                color: $white;
                width: 6rem;
                text-align: center;
                font-size: 22px;
              }

              h5 {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
              }

              p {
                margin: 0;

                small {
                  opacity: 0.56;
                  font-size: 12px;
                  color: #ffffff;
                }
              }

              a {
                font-size: 14px;
                text-decoration: underline;
                color: $white;
              }

              & + li {
                border-top: 1px solid #3e3e3e;
              }
            }
          }

          &.gambling {
            > * {
              padding: 1rem 1.25rem 1rem;
            }

            .block-footer {
              border-top: 1px solid #3e3e3e;
            }

            a {
              font-size: 14px;
              text-decoration: underline;
              color: $white;
            }
          }
        }
      }

      section + section {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid #3e3e3e;
      }
    }
  }
}

.featured-coupon {
  padding: 1rem 1.25rem;
  box-shadow: 0px 2px 21px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background: #121216;

  > div:first-child {
    margin-bottom: 2rem;

    img {
      height: 47px;
    }
  }

  .coupon-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      color: #8790ab;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    h3 {
      color: $white;
      font-size: 22px;
      font-weight: 600;
    }
  }

  .btn-primary {
    border-radius: 5px;
    background: $loginPanelButtonBG;
    color: $white;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    padding: 0.625rem 1rem;
  }

  .advert-title {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.7258;
  }

  .advert-value {
    color: $white;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 30px;
  }

  .featured-coupon-detail {
    display: flex;
    flex-direction: column;
  }
}

#screen-disabled {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    max-width: 540px;
    padding: 47px 65px;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: -1px;
  }

  p {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
}

#page-not-found {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 100%;

  span {
    margin-top: 4vh;
    font-size: 5rem;
  }

  a {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

#upcoming-leagues-page,
#my-bets-page {
  .league_sidebar {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .dashboard_leagues {
    border-radius: 5px 0px 5px 5px;
    border: 1px solid $panelBorder;
    background: $panelBG;
    padding: 1rem 1.625rem;
    margin-bottom: 1.625rem;

    .filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 1.25rem;
    }

    .leagues_header {
      margin: 0;
    }

    .leagues_list {
      width: 100%;
      gap: 20px;
      display: flex;
      flex-direction: column;
      .league {
        width: 687px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #0b0e1d;
        background: #1b1e2e;

        .league_icon {
          height: 100%;
          width: auto;

          img {
            border-radius: 5px 0px 0px 5px;
            height: 100%;
          }
        }

        .league-split {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-right: 1px solid $panelBorder;
          padding-right: 0.5rem;

          .upper {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
          }

          .lower {
            display: flex;
            border-top: 1px solid $panelBorder;
            margin-top: 10px;
            padding-top: 10px;

            .position {
              width: 47.379px;
              height: 27px;
              flex-shrink: 0;
              border-radius: 13.5px;
              background: #00ffae;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $panelBG;
              text-align: center;
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 0.25rem;
            }
          }
        }

        .league_titles {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {
            color: #8790ab;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 0;
          }

          h3 {
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }

        .league_details {
          display: flex;
          flex-direction: column;
          align-items: center;

          .value {
            font-size: 14px;
            font-weight: 400;
            color: $white;
          }

          .entries {
            color: #8790ab;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
          }
        }

        .league_status {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 0;

          .position {
            width: 52px;
            border-radius: 40px;
            min-height: 27px;
            line-height: 27px;
            font-size: 14px;
            color: $dark;
            display: flex;
            justify-content: center;
            text-transform: none;
            font-weight: 500;
            background: $highlightGreen;

            color: $panelBG;
            font-size: 14px;
            font-weight: 700;
          }
        }

        .league-buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          a {
            margin-left: 10px;

            i {
              font-size: 12px;
              color: $white;
              display: inline-block;
              width: 31px;
              height: 31px;
              text-align: center;
              line-height: 31px;
              background: $panelBorder;
              border-radius: 32px;
            }

            &.disabled {
              opacity: 0.25;
            }

            &.link {
              display: block;
              text-align: center;
              background: none;
              margin: 0 0.5rem;

              i {
                background: none;
                color: $white;
                font-size: 25px;
              }
            }
          }
        }

        .league_actions {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          a {
            margin-left: 10px;

            i {
              font-size: 12px;
              color: $white;
              display: inline-block;
              width: 31px;
              height: 31px;
              text-align: center;
              line-height: 31px;
              background: $panelBorder;
              border-radius: 32px;
            }

            &.disabled {
              opacity: 0.25;
            }

            &.link {
              display: block;
              text-align: center;
              background: none;
              margin: 0 0.5rem;

              i {
                background: none;
                color: $white;
                font-size: 25px;
              }
            }
          }
        }
      }
    }
  }
}

.sidebar_block {
  border: 1px solid $panelBorder;
  border-radius: 6px;
  margin-top: 1.5rem;

  h4 {
    font-size: 16px;
    font-weight: bold;
    padding: 1.25rem 1.25rem 0;
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      flex-direction: row;
      padding: 1rem 1.25rem 1rem 0;
      i {
        color: $white;
        width: 6rem;
        text-align: center;
        font-size: 22px;
      }

      h5 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }

      p {
        margin: 0;

        small {
          opacity: 0.56;
          font-size: 12px;
          color: #ffffff;
        }
      }

      a {
        font-size: 14px;
        text-decoration: underline;
        color: $white;
      }

      & + li {
        border-top: 1px solid #3e3e3e;
      }
    }
  }

  &.gambling {
    > * {
      padding: 1rem 1.25rem 1rem;
    }

    .block-footer {
      border-top: 1px solid #3e3e3e;
    }

    a {
      font-size: 14px;
      text-decoration: underline;
      color: $white;
    }
  }
}

.secured-by-block {
  background: #ffffff;
  border-radius: 3px;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-left: auto;
    width: 110px;
  }

  span {
    line-height: 1;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-left: 1.5rem;
    display: block;
  }

  i {
    color: $red;
  }
}

/****   AUTHENTICATION          ****/

main.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: url("../../../../public/images/ES-bg.png") no-repeat center
    center#1B1E2E;
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 100vh;

  .error404 {
    padding-bottom: 50px;
    max-width: 500px;
    padding: 0 30px;
    text-align: center;
    margin-top: -20vh;
    .errorHead {
      h1 {
        opacity: 0.2;
        font-weight: 300;
        font-size: 12.5vh;
        text-align: center;
        margin-top: 20px;
      }
    }
    .errorBody {
      text-align: left;
      color: white;
      margin-top: 30px;
      h2 {
        text-align: center;
        font-size: 32px;
      }
      p {
        text-align: center;
        font-size: 18px;
        font-weight: 300;
      }
      .btn {
        font-size: 14px;
        margin: 20px 10px 0;
      }
    }
  }

  .copy {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: $textLight;
  }
}

/**   Two_col layout         **/

.two_col {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
    width: 100%;
    margin-top: 60px;
    min-height: calc(100vh - 60px);
  }
}

/**   One_col layout         **/

/**   Header         **/

.two_col,
.one_col {
  background: url("../../../../public/images/ES-bg.png") 50% 50% no-repeat
    #1b1e2e;
  background-size: cover;
  background-attachment: fixed;

  header {
    height: 60px;
    background: #141724;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem 0 0.75rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    .brand {
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        display: block;

        & + a {
          margin-left: 0.5rem;
        }
      }
    }

    .account {
      display: flex;
      flex-direction: row;
      align-items: center;
      > * {
        margin-left: 0.75rem;
      }

      &.loggedIn {
        .icon {
          margin-right: 0.75rem;
          display: block;
          i {
            color: $white;
            font-weight: 500;
            font-size: 20px;
            line-height: 1.5;
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .userProfile {
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: $primary;
          color: $white;
          text-align: center;
          line-height: 2.5;
          font-size: 13px;
          font-weight: bold;
          position: absolute;
          top: -2px;
          right: 0;
        }

        .budget {
          height: 28px;
          width: 100%;
          position: relative;
          display: block;
          background: rgba($white, 0.25);
          border-radius: 14px;
          font-weight: bold;
          font-size: 13px;
          padding: 5px 43px 5px 12px;
          color: $white;
        }
      }
    }
  }
}

/**   Aside       **/

.two_col {
  h1 {
    margin: 2.75rem 50px;
    font-size: 28px;
    font-weight: 700;
    @include media-breakpoint-down(md) {
      margin: 2rem 2rem;
      font-size: 24px;
    }

    @include media-breakpoint-down(xs) {
      margin: 1.5rem 1.25rem;
      font-size: 20px;
    }
  }

  .content {
    > aside {
      max-width: 225px;
      flex: 0 0 225px;
      background: $panelBG;
      transition: all 0.35s ease;
      position: relative;
      top: 0;

      @media (max-width: 1199px) {
        position: fixed;
        max-height: 100%;
        width: 225px;
        height: calc(100% - 60px);
        bottom: 0;
        top: 60px;
        left: -225px;
        overflow-y: auto;
        z-index: 20;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.55);
      }

      section {
        & + section {
          margin-top: 1.25rem;
        }
      }

      h3 {
        font-weight: 400;
        font-size: 11px;
        color: $textLight;
        letter-spacing: 0;
        text-transform: uppercase;
        padding: 1.25rem 1.25rem 0;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          width: 100%;
          position: relative;
          top: 0;
          padding-right: 12px;
          a,
          span {
            padding: 0.25rem 1.25rem;
            color: $white;
            font-size: 14px;
            display: block;
            font-weight: 400;
            text-transform: uppercase;
          }
          &.disabled {
            opacity: 0.25;
          }
          &.fav::after {
            display: block;
            content: "\f005";
            font-family: "Font Awesome 5 Pro";
            font-size: 8px;
            position: absolute;
            top: 9px;
            right: 13px;
            font-weight: 900;
            color: $highlightGreen;
          }
          &.active {
            border-left: 3px solid $highlightGreen;
            a {
              color: $highlightGreen;
              padding-left: calc(1.25rem - 3px);
            }
          }
        }
      }
      footer {
        position: absolute;
        bottom: 20px;
        left: 1.25rem;
        p {
          font-size: 14px;
          color: $textLight;
        }
      }
    }

    > main {
      flex-grow: 1;
      overflow-y: auto;
      box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.25);
    }
  }
}

@media (max-width: 1199px) {
  body.open {
    .two_col .content {
      > aside {
        left: 0;
      }
    }
  }
}

/**   Accounts Tabs       **/

#account_tabsContent {
  background: $panelBG;
  border: 1px solid $panelBorder;
  border-radius: 6px;
  border-top-left-radius: 0;
  padding: 3rem 4.5rem;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);

  @include media-breakpoint-down(lg) {
    padding: 0;
    border: 0;
    background: none;

    .tab-pane {
      background: $panelBG;
      border: 1px solid $panelBorder;
      border-radius: 6px;
      border-top-left-radius: 0;
      padding: 1rem 1rem;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    }
  }
}

.nav-tabs {
  border-bottom: 0;
  padding: 0;

  @include media-breakpoint-down(md) {
    padding: 0rem 2rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 0rem 1.25rem;
  }

  .nav-item {
    & + .nav-item {
      .nav-link {
        margin-left: 2px;
      }
    }
  }

  .nav-link {
    background: $dark;
    font-size: 13px;
    color: rgba($white, 0.25);
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: bold;
    border: 0;
    border-radius: 0;
    padding: 0.85rem 2.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media (max-width: 1600px) {
      padding: 0.85rem 1.5rem;
    }

    @media (max-width: 1400px) {
      font-size: 12px;
      padding: 0.85rem 1rem;
    }

    &.active {
      opacity: 1;
      color: $white;
      border: 0;
      background: $panelBG;
      box-sizing: border-box;
      border: 1px solid $panelBorder;
      border-bottom: 0;
    }
  }
}

/**   Accounts Layout       **/

.myAccount_Container {
  .title_container {
    position: relative;
    padding-right: 3rem;
    .toggle_filters {
      position: absolute;
      bottom: 0.25rem;
      right: 0;
      padding: 0.5rem 0.5rem;
      text-align: center;
      i {
        text-align: center;
        display: block;
        margin-right: 0;
      }

      &.active,
      &:focus.active {
        background: $white;
        color: #111;
      }

      &:focus {
        background: none;
        color: $white;
      }
    }
  }

  .account_back_link {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    color: white;
    margin-bottom: 1rem;
    font-size: 13px;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid $highlightBlue;
    line-height: 18px;
    margin-bottom: 20px;
    display: inline-block;

    i {
      margin-right: 0.5rem;
      font-size: 18px;
      position: relative;
      top: 2px;
      color: $highlightBlue;
    }

    &:hover {
      color: $white;
      text-decoration: underline;

      i {
        text-decoration: none;
      }
    }
  }

  .tab_container {
    padding: 0 20px;

    @include media-breakpoint-up(sm) {
      padding: 0 32px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 50px;
    }
  }

  .nav-tabs {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .tab-pane.active {
    display: flex;
    flex-direction: row;
  }

  #account_buttons {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      margin: 0;
    }

    li {
      list-style-type: none;
      max-width: calc(33.33% - 13.33px);
      flex: 0 0 calc(33.33% - 13.33px);
      margin-bottom: 1.25rem;

      @media (max-width: 599px) {
        max-width: calc(50% - 8px);
        flex: 0 0 calc(50% - 8px);
        margin-bottom: 1rem;
      }

      a {
        background: #111;
        border-radius: 5px;
        text-align: center;
        display: block;
        padding: 2.5rem 1rem;
        color: $white;
        font-size: 14px;
        border: 1px solid $highlightBlue;

        i {
          display: block;
          font-size: 36px;
          text-align: center;
          margin-bottom: 1rem;
          color: $highlightBlue;
        }

        span {
          display: block;
        }
        @media (max-width: 599px) {
          padding: 2rem 1rem;
        }

        &:hover {
          text-decoration: none;
          background: darken(#111, 10%);
        }
      }
    }
  }

  main {
    flex-grow: 1;

    .empty_state {
      overflow: hidden;
      background: rgba(255, 255, 255, 0.1);
      position: relative;
      text-align: center;
      margin-bottom: 1.25rem;
      padding: 2rem;
      border-radius: 12px;

      i {
        color: $white;
      }

      h3 {
        margin: 1rem 0;
      }
    }

    .alert {
      margin-bottom: 2rem;
    }

    form {
      max-width: 600px;

      section {
        & + section {
          margin-top: 2rem;
          padding-top: 2rem;
          border-top: 1px solid #3e3e3e;
        }
      }
    }

    h2 {
      margin-bottom: 1rem;

      @include media-breakpoint-down(xs) {
        margin-bottom: 0.5rem;
        font-size: 18px;
      }
    }

    h2 ~ p {
      margin-bottom: 1.5rem;
      @include media-breakpoint-down(xs) {
        margin-bottom: 0.75rem;
      }
    }

    h3 {
      margin-bottom: 1rem;

      @include media-breakpoint-down(xs) {
        margin-bottom: 0.75rem;
        font-size: 15px;
      }
    }

    @include media-breakpoint-down(sm) {
      .table-container {
        position: relative;
        width: calc(100vw - 64px);
      }
    }

    @include media-breakpoint-down(xs) {
      .table-container {
        width: calc(100vw - 40px);
      }
    }

    @include media-breakpoint-down(xs) {
      .footer_btns {
        .btn {
          width: 100%;
          margin: 0 0 1rem;
        }
      }
    }
  }

  aside {
    max-width: 30%;
    flex: 0 0 30%;
    margin-left: 6rem;

    @media (max-width: 1200px) {
      margin-left: 2rem;
    }

    @media (max-width: 991px) {
      display: none;
    }

    .balance_container {
      margin-bottom: 1.5rem;
    }

    .secured-by-block {
      margin-top: 1.5rem;
    }
  }

  #deposit aside,
  #withdraw aside,
  #details aside,
  #add-card aside {
    @media (min-width: 800px) {
      display: block;
    }

    @media (max-width: 991px) {
      max-width: 35%;
      flex: 0 0 35%;
    }
  }
}

/**   Enter Betslip Layout       **/

.betslip_title {
  display: flex;
  flex-direction: row;
  background: #161616;
  justify-content: space-between;
  .betslip_icon {
    max-width: 75px;
    flex: 0 0 75px;
    min-height: 75px;
    background: #ff2982;
  }

  .betslip_name {
    padding: 1rem;
    color: $white;

    h5 {
      line-height: 1;
      opacity: 0.82;
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
      text-transform: uppercase;
    }

    h2 {
      font-size: 22px;
      color: #ffffff;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .countdown {
    padding: 1rem;
  }
}

.league-head {
  width: 100%;
  z-index: 10000;

  .title-row {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid $panelBorder;
    background: linear-gradient(180deg, #1b1f2e 0%, #171b2a 100%);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

    .league_icon {
      border-radius: 2px 0px 0px 2px;
      background: #545a7b;
      width: 55px;
      height: 55px;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
    }

    .title {
      margin-left: 1.25rem;
      h3 {
        color: #8790ab;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
      }
      h4 {
        color: $white;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
      }
    }

    .fixture {
      border: 0;
      margin: 0 auto;
      background: none;
      .fixture-vs {
        border: 0;
        background: none;
        padding: 0;
        .score {
          font-size: 22px;
        }
      }
    }

    .live {
      .live-block {
        background: $highlightGreen;
        color: $dark;
        padding: 0 12px;
        line-height: 40px;
        border-radius: 4px;
        font-size: 16px;
      }
    }

    .event-name {
      border-radius: 25px;
      background: #333953;
      color: $white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      padding: 0.25rem 1.25rem;
    }

    .countdown {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h4 {
        color: #8790ab;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
      }
      .countdown_block {
        margin-top: 0.6rem;
        span {
          color: $white;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

#league-details-page {
  .league-details {
    flex-grow: 0;
  }

  .myBetslip {
    margin-top: auto;
    button.nav-link {
      border: 1px solid #363b56;
      background: #1f2334;
      border-radius: 0;

      &.active {
        border-top: 2px solid #2396d5;
      }
      &:focus {
        outline: none;
      }
    }

    #myTabContent {
      border: 1px solid #363b56;
      background: #1f2334;
      color: #fff;
      .single {
        .league {
          border-radius: 15px;
          background: #2a304b;
          margin: 9px;
          padding: 0.2rem;
        }
        .position {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
        }
        .user {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          > div {
            display: flex;
            align-items: center;
          }
          .avatar {
            margin-right: 7.4px;
          }
        }
        .points {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          > span {
            color: #fff;
            text-align: center;
            font-size: 11px;
            font-weight: 400;
            opacity: 0.5;
            margin-left: 2px;
          }
        }
      }
    }
  }
  .league-detail-wrapper {
    .league-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
      h2 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      a {
        color: #fff;
        font-family: "Avenir Next";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
      }
    }

    .header {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px;
      text-transform: uppercase;
      opacity: 0.5;
      margin: 0 0 11px 0;
    }

    .league-table {
      display: flex;
      flex-direction: column;
      gap: 6px;
      color: #fff;

      .league {
        height: 34px;
        margin: 0;
      }

      .position {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
      }
      .user {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        > div {
          display: flex;
          align-items: center;
        }
        .avatar {
          margin-right: 7.4px;
        }
      }
      .points {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        > span {
          color: #fff;
          text-align: center;
          font-size: 11px;
          font-weight: 400;
          opacity: 0.5;
          margin-left: 2px;
        }
      }
    }

    .league {
      &.row {
        border: 1px solid #363b56;
        background: #1f2334;
      }
    }
  }

  .league-details {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #181b2b;

    .league-detail-header {
      display: flex;
      align-items: center;
      margin-bottom: 1.25rem;

      h4 {
        margin: 0;
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .event-name {
      border-radius: 25px;
      background: #333953;
      color: $white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      padding: 0.25rem 1.25rem;
    }

    .league-detail-fixture {
      border-radius: 5px;
      border: 2px solid #2396d5;
      background: #1f2334;
      padding: 1.5rem 0;
      // width: calc(50% - 20px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      min-width: 360px;
      // align-self: flex-start;

      > .countdown {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        > h4 {
          color: #8790ab;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      .countdown_block {
        width: 146px;
        display: flex;
        padding: 8px 20px;
        align-items: flex-start;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid #333953;
        background: #181b2b;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .fixture {
        display: flex;
        gap: 15px;
        align-items: center;
        .team {
          display: flex;
          gap: 15px;
          align-items: center;
          text-align: right;
          width: 50%;
          justify-content: flex-end;
          &.teamB {
            flex-direction: row-reverse;
            text-align: left;
          }

          .teamName {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
          }
        }

        p {
          margin: 0;
        }

        img {
          max-width: 40px;
          flex: 0 0 40px;
          object-fit: contain;
        }

        .vs {
          color: $white;
          font-size: 12px;
          text-transform: uppercase;
        }
      }

      h3,
      h4 {
        margin: 0;
      }

      h3 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .league-video {
      display: flex;

      .stats {
        background: #1f2334;
        width: 286px;
        border: 1px solid #333953;
        border-radius: 0 5px 5px 0;

        .title {
          padding: 1rem;
          .countdown {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            h4 {
              color: #8790ab;
              font-size: 14px;
              font-weight: 700;
              text-transform: uppercase;
            }
          }

          .fixture {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .team {
              width: 40%;

              &.teamA {
                text-align: right;
              }
            }
          }
        }

        .stat-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 1rem;
          max-height: 365px;
          overflow-y: scroll;
          border-top: 1px solid #363b56;

          h4 {
            color: #8790ab;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }

          .progress {
            height: 5px;
            margin-top: 5px;

            &.even {
              background: #00ffae;
              .progress-bar {
                background-color: #2396d5;
              }
            }

            &.odd {
              background: #2396d5;
              .progress-bar {
                background-color: #00ffae;
              }
            }
          }
          .fixture {
            display: flex;
            gap: 15px;
            align-items: center;
            .team {
              display: flex;
              gap: 12px;
              align-items: center;
              text-align: right;
              width: 50%;
              justify-content: flex-end;
              &.teamB {
                flex-direction: row-reverse;
                text-align: left;
              }

              .teamName {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
              }
            }

            p {
              margin: 0;
            }

            img {
              max-width: 40px;
              flex: 0 0 40px;
              object-fit: contain;
            }

            .vs {
              color: $white;
              font-size: 12px;
              text-transform: uppercase;
            }
          }
        }

        .stat {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-radius: 5px;
          border: 1px solid #363b56;
          background: #1f2334;
          padding: 0.625rem;

          h4 {
            color: #8790ab;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }

          img {
            max-height: 40px;
          }
        }
      }

      .video-wrapper {
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #363b56;

        .coupon-fixtures {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          flex-grow: 1;

          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

#enter-league {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  max-width: calc(100% - 225px - 400px);

  @media only screen and (max-width: 1200px) {
    max-width: calc(100% - 400px);
  }

  .betslip {
    background: $panelBG;
    width: 400px;
    flex: 0 0 400px;
    padding: 1.5625rem;
    position: relative;
    top: 0;
    overflow-y: scroll;
    height: calc(100vh - 60px);
    padding-bottom: 100px;
    position: fixed;
    top: 60px;
    right: 0;
    gap: 20px;
    display: flex;
    flex-direction: column;

    .fixture {
      width: 350px;
    }

    .title {
      display: flex;
      justify-content: space-between;

      .badge {
        border-radius: 5px;
        border: 1px solid $panelBorder;
        background: #1b1e2e;
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    h2 {
      color: $white;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    h4 {
      color: $textLight;
    }

    .bet-panel {
      background: #171a29;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      padding: 1rem;
      gap: 12px;

      &.no-answers {
        display: flex;
        padding: 40px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }

      &.upcoming {
        opacity: 0.25;
      }

      h3 {
        font-weight: 400;
        font-size: 15px;
      }

      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        border: 1px solid $panelBorder;
        background: $panelBG;
        padding: 0.625rem 1rem;
        align-items: flex-end;

        .badge.badge-pill.badge-info {
          padding: 0 1rem;
          height: 20px;
          flex-shrink: 0;
          border-radius: 100px;
          border: 1px solid $panelBorder;
          background: #1b1e2e;
          color: $white;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
        }

        .item-answer {
          display: flex;
          flex-direction: column;

          h4 {
            color: #8790ab;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
          }
        }

        .label {
          color: $white;
          font-weight: 500;
        }

        img {
          max-width: 40px;
          flex: 0 0 40px;
          margin: 0 0.5rem;
          object-fit: contain;
          align-self: flex-end;
        }

        .close {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          color: $white;
          font-size: 15px;
          text-shadow: none;
        }
      }
    }
    .btn-container {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 400px;
      padding: 1.5625rem;
      background: linear-gradient(
        180deg,
        rgba(31, 35, 52, 0) 0%,
        rgba(31, 35, 52, 1) 20%
      );
    }

    .chat {
      margin: 0 0 -100px;
      height: calc(100% + 100px);
      width: 100% !important;
      max-width: none;
    }
  }

  .fixture {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    width: 450px;
    justify-content: center;
    p {
      margin: 0;
    }
    .team {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $white;
      text-align: right;
      flex: 1 1 50%;
      &.teamA {
        justify-content: flex-end;
        text-align: right;
        margin-left: auto;
      }
      &.teamB {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-right: auto;

        .teamName {
          text-align: left;
        }
      }
      .teamName {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
      }
      img {
        max-width: 40px;
        flex: 0 0 40px;
        margin: 0 0.5rem;
        object-fit: contain;
      }
    }
    .vs {
      padding: 0 0.75rem;
      color: $white;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    width: 100%;

    .accordion {
      .completed {
        border-radius: 5px;
        border: 2px solid $loginPanelCrossSellLinkColor;
        background: $panelBG;
      }
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0;

        i {
          color: $white;
          cursor: pointer;
        }

        p.date {
          width: 77px;
          color: $white;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          margin: 0;
        }

        .badge.badge-pill {
          width: 58px;
          border-radius: 100px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 1.75rem;

          &.badge-light {
            border: 1px solid $panelBorder;
            background: #1b1e2e;
            color: $white;
          }

          &.badge-primary {
            border: 2px solid $loginPanelCrossSellLinkColor;
            background: #1b1e2e;
            color: $loginPanelCrossSellLinkColor;
          }

          &.all-answered {
            margin-right: 0;
          }
        }
      }

      .card-body {
        &.questions {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .question {
            border-bottom: 1px solid $panelBorder;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 30px;

            &:last-child {
              border: 0;
              padding-bottom: 0;
            }

            .bonus-question {
              border-left: 3px solid #565968;
              background: #282c3d;
              display: flex;
              padding: 15px 15px 15px 47px;
              flex-direction: column;
              align-items: flex-start;
              gap: 15px;
              align-self: stretch;

              > div {
                width: 100%;
              }
            }

            .form-group {
              &.numeric {
                margin: 0;
                display: flex;
                align-items: flex-start;
                gap: 20px;
                align-self: stretch;

                input {
                  width: 62px;
                  height: 44px;
                  flex-shrink: 0;
                  border-radius: 5px;
                  background: #2d303c;
                  box-shadow: 0px 0px 0px 3px rgba(35, 150, 213, 0.35);

                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  &[type="number"] {
                    -moz-appearance: textfield; /* Firefox */
                    appearance: textfield;
                  }

                  &.populated {
                    border: 2px solid $loginPanelCrossSellLinkColor;
                  }
                }

                button {
                  display: flex;
                  width: 44px;
                  height: 44px;
                  padding: 10px 20px;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;
                  border-radius: 5px;
                  border: 1px solid #fff;
                  background: #1d202e;
                  color: $white;
                }
              }
            }
          }

          h3 {
            color: $white;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
          }

          .badge-pill.badge-info {
            height: 31px;
            flex-shrink: 0;
            border-radius: 100px;
            border: 1px solid $panelBorder;
            background: #1b1e2e;
            color: $white;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1rem;
          }

          .team-group {
            display: flex;
            gap: 20px;
            justify-content: space-between;

            .team {
              display: flex;
              flex-direction: column;
              width: 50%;
              gap: 10px;

              .teamTitle {
                color: rgba(255, 255, 255, 0.5);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }

              > .form-check {
                width: 100%;
              }
            }

            .form-check {
              width: 50%;
              padding: 10px 20px;
              border: 1px solid #2f344c;
              background: #1d202e;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &.is-selected {
                border: 2px solid $loginPanelCrossSellLinkColor;
                background: #1d202e;
              }

              > div {
                display: flex;
                gap: 10px;
                align-items: center;

                label {
                  margin: 0;
                }
              }

              input {
                margin: 0;
                position: relative;

                &[type="radio"] {
                  display: grid;
                  place-content: center;

                  appearance: none;
                  background-color: #1a1c28;
                  margin: 0;
                  font: inherit;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  border: 2px solid #2f344c;

                  &::before {
                    content: "";
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em $loginPanelCrossSellLinkColor;
                  }

                  &[value="true"]::before {
                    transform: scale(1);
                  }
                }
              }

              label {
                display: flex;
                align-items: center;
              }

              .profile {
                display: flex;
              }
            }

            label {
              color: $white;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            img {
              max-height: 43px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .confirmation {
    border-radius: 5px;
    border: 1px solid $white;
    color: $white;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    width: 142px;
  }

  .league_head {
    .title {
      h3 {
        color: #8790ab;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }

  .card.help-message {
    border-radius: 6px;
    border: 1px solid $panelBorder;
    background: linear-gradient(0deg, $panelBorder 0%, #1a1e2e 106.58%);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 1.25rem;

    .card-body {
      h3 {
        color: $white;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1rem;
      }

      .breadcrumb ul li {
        margin-right: 1rem;
      }
    }

    .card-footer {
      background: transparent;

      a.text-link {
        font-size: 14px;
      }

      span {
        font-size: 18px;
      }
    }
  }

  .entry-body {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .entry-name {
      display: flex;
      justify-content: space-between;
      h3 {
        margin: 0;
        color: $white;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    #enter-league-success {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      width: 678px;
      margin: 0 auto;
      border: 0;

      .card-body {
        border-radius: 5px 5px 0px 0px;
        border: 1px solid #333953;
        background: #181b2b;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 30px 0;

        img {
          margin-bottom: 0.625rem;
        }

        h4 {
          color: #8790ab;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          margin: 0;
          max-width: 370px;
        }

        h5 {
          color: #fff;
          text-align: center;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
          max-width: 370px;
        }
      }

      .card-footer {
        width: 100%;
        display: flex;
        padding: 20px;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        border-radius: 0px 0px 5px 5px;
        border-right: 1px solid $panelBorder;
        border-bottom: 1px solid $panelBorder;
        border-left: 1px solid $panelBorder;
        background: $panelBG;

        > a {
          height: 46px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            background: $loginPanelButtonBG;
            width: 181px;
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }

          &:last-child {
            border: 2px solid $loginPanelButtonBG;
            width: 208px;
            color: $loginPanelButtonBG;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .entry-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.25rem;
    width: 100%;
    border-top: 1px solid $panelBorder;
    background: linear-gradient(180deg, #171b2a 0%, #1b1f2e 100%);
    box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 225px;
    width: calc(100% - 225px - 400px);
    z-index: 10000;

    button {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.75rem;
      width: 60px;

      i {
        margin: 0;
      }
    }

    .current {
      color: $white;
      font-weight: 500;
      text-align: center;

      span {
        display: block;
        color: $textLight;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .entry_form {
    .nav-tabs {
      padding: 0 0 1px;
      border-bottom: 1px solid $highlightGreen;

      .nav-link.active {
        border: 0;
        background: $highlightGreen;
        color: $dark;
      }
    }

    .tab-pane {
      h3 {
        font-weight: 500;
        margin: 2rem 0 1rem;
      }
    }
  }
}

.league-detail {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 400px;
  padding-right: 400px;

  .league-head {
    padding: 0 !important;
  }

  .league-video {
    margin-top: 87px;
    display: flex;
    width: 100%;
    height: 500px;

    .videoWrapper {
      position: relative;
      //padding-bottom: 56.25%; /* 16:9 */
      //height: 0;
      flex: 1 1 calc(100% - 300px);
      height: 500px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .stats {
      width: 300px;
      height: 500px;
      background: #141724;
      padding: 1rem;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;
        h3 {
          font-size: 14px;
          text-transform: uppercase;
          margin: 0;
        }
        a {
          font-size: 14px;
          color: $highlightBlue;
        }
      }

      .stat {
        margin-bottom: 0.5rem;
        h4 {
          font-size: 14px;
          color: $textLight;
        }
        .detail-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 1rem;

          .number {
            padding-right: 2rem;
            font-size: 32px;
            font-weight: 300;
            color: $white;
          }

          .graph {
            border-radius: 4px;
            background: $panelBG;
            width: 200px;
            height: 50px;
          }
        }
      }
    }
  }

  #dashboard-page {
    .league-detail-wrapper {
      display: flex;
      width: 100%;

      .league-main {
        flex-grow: 1;
        width: calc(100% - 300px);
        padding: 1.25rem;

        .leagues_header {
          width: 100%;

          h2 {
            line-height: 35px;
          }
        }

        .leagues_list {
          width: 100%;
          padding-left: 9px;
          padding-right: 9px;

          .header {
            font-size: 12px;
            text-transform: uppercase;
            color: $textLight;
            padding: 5px 0;

            .position {
              padding-left: 16px;
            }
          }

          .league {
            padding-top: 5px;
            padding-bottom: 5px;
            align-items: center;

            border-radius: 3px;
            border: 1px solid #27a0da;
            background: $panelBG;
            box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);

            .position {
              color: $white;
              font-size: 18px;
              font-weight: 500;
              line-height: 22px;
              padding-left: 20px;

              i {
                font-size: 12px;
                position: relative;
                top: -2px;
                &.fa-chevron-up {
                  color: $success;
                }
                &.fa-chevron-down {
                  color: $danger;
                }
              }
            }

            .user {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .avatar {
                margin-right: 10px;
                border-radius: 20px;
                width: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                color: white;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;
                background-color: $highlightBlue;
              }
              .username {
                font-weight: 500;
                color: white;
                font-size: 16px;
                span {
                  display: block;
                  font-weight: 400;
                  font-size: 12px;
                  color: $textLight;
                }
              }
            }

            .winnings {
              .winning {
                display: inline-block;
                padding: 4px 16px;
                font-size: 12px;
                text-transform: uppercase;
                border-radius: 3px;
                margin-right: 0.5rem;
                background: rgba($success, 0.2);
                border: 2px solid $success;
                color: $success;
              }
            }

            .points {
              color: $white;
              font-size: 18px;
              font-weight: 300;

              span {
                font-size: 14px;
                color: $textLight;
              }
            }

            .cta {
              justify-content: flex-end;
              padding-right: 20px;
              color: $white;
              font-weight: 500;
              text-align: right;
            }
          }
        }
      }

      .league-sidebar {
        padding-top: 1.25rem;
      }
    }
  }

  .myBetslip {
    position: fixed;
    bottom: 0;
    left: 225px;
    width: calc(100vw - 225px - 400px);

    .showHide {
      position: absolute;
      top: 0;
      right: 20px;

      > div {
        display: none;
        padding: 3px 15px;
        border-radius: 30px;
        background: $panelBG;
        border: 1px solid $panelBorder;
        color: $textLight;
        font-size: 14px;

        i {
          margin-left: 20px;
        }
      }

      .visible {
        display: inline-block;
      }
    }

    .myBetslip-tabs {
      display: flex;
      margin: 0;
      padding: 0;
      padding-left: 1.25rem;

      .nav-item {
        position: relative;
        top: 0px;
        z-index: 1000;
        margin: 0;
        padding: 0;
        button {
          line-height: 22px;
          font-size: 16px;
          color: $white;
          background: #141724;
          padding: 6px 18px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border: 1px solid $panelBorder;
          border-bottom: 0;
          height: 34px;
          margin-right: 6px;

          span {
            font-size: 14px;
            color: $textLight;
          }

          &.active {
            background: $highlightGreen;
            color: $dark;
            height: 35px;
          }

          &:focus {
            outline: 0;
          }
        }
      }
    }

    .myBetslip-tab-content {
      background: linear-gradient(180deg, #2d314a 0%, #22273a 100%);
      border-top: 1px solid $panelBorder;
      padding: 1.25rem;

      h2 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 1.25rem;
      }

      .overall {
        border-right: 1px solid $panelBorder;

        h4 {
          font-size: 12px;
          color: $textLight;
          text-transform: uppercase;
          font-weight: 400;
        }

        h6 {
          font-size: 11px;
          color: $textLight;
        }

        .position {
          color: $white;
          font-size: 32px;
          font-weight: 300;
          line-height: 55px;

          span {
            font-size: 16px;
            color: $textLight;
          }

          i {
            font-size: 16px;
            position: relative;
            top: -5px;
            &.fa-chevron-up {
              color: $success;
            }
            &.fa-chevron-down {
              color: $danger;
            }
          }
        }

        .points {
          color: $white;
          font-size: 32px;
          font-weight: 300;
          line-height: 55px;

          span {
            font-size: 16px;
            color: $textLight;
          }
        }
      }

      .sections {
        padding-left: 30px;
        margin-top: -45px;
        ul.nav {
          display: flex;
          margin: 0 0 0.825rem;
          padding: 0;
          .nav-item {
            margin: 0;
            padding: 0;
            button {
              background: #141724;
              margin-right: -1px;
              border: 1px solid $panelBorder;
              padding: 5px 10px;
              color: $white;
              font-size: 14px;
              font-weight: 400;
              &.active {
                background: $panelBorder;
              }
              &:focus {
                outline: 0;
              }
            }
            &:first-child button {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            &:last-child button {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
        .tab-content {
          .col-lg-6 {
            max-width: 350px;
          }
          h3 {
            font-size: 14px;
            font-weight: 400;
            color: $textLight;
            margin-bottom: 3px;
            margin-top: -2px;
          }
          .performace {
            display: flex;
            justify-content: space-between;
            .name {
              color: $white;
              font-weight: 500;
              font-size: 18px;
              h6 {
                font-size: 11px;
                color: $textLight;
                font-weight: 400;
              }
            }
            .points {
              padding-top: 12px;
              font-size: 22px;
              font-weight: 300;
              color: $white;
              position: relative;
              top: 0;
              span {
                font-size: 16px;
                color: $textLight;
              }
              .boost {
                position: relative;
                top: -1rem;
                display: inline-flex;
                width: 28px;
                height: 28px;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                background: $danger;
                color: $white;
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
          .status {
            span {
              display: inline-block;
              padding: 4px 16px;
              font-size: 12px;
              text-transform: uppercase;
              border-radius: 3px;
              margin-right: 0.5rem;
              &.confirmed {
                background: rgba($success, 0.2);
                border: 2px solid $success;
                color: $success;
              }
              &.projected {
                background: rgba($textLight, 0.2);
                border: 2px solid $textLight;
                color: $textLight;
              }
              &.confirmed-check {
                padding: 0;
                width: 25px;
                height: 25px;
                border-radius: 25px;
                text-align: center;
                line-height: 25px;
                color: white;
                display: inline-block;
                background: $success;
              }
            }
          }
        }
      }
    }
  }
}

.policies {
  h1 {
    margin-left: 0;
  }

  .sidebar {
    position: relative;
    top: 0;
    height: calc(100vh - 300px);

    .list-group {
      position: sticky;
      top: 120px;
    }
  }

  .panel {
    padding: 2rem;
    background: $panelBG;
    border: 1px solid $panelBorder;
    border-radius: 10px;
    height: calc(100vh - 300px);
    overflow-y: scroll;

    p {
      font-size: 16px;
    }
  }

  .list-group-item {
    border-radius: 4px;
    border: 1px solid $panelBorder;
    background: #141724;
    color: white;
    display: inline-block;
    margin-bottom: 3px;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.active {
      background: $highlightBlue;
      color: $white;
    }
  }
}

#dashboard-page {
  .leagues_list {
    .no-leagues {
      border-radius: 10px;
      border: 1px solid #323751;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 51px 10px 44px 10px;
      background: url("../../../../public/images/bg-soldier-2.png") bottom right
        no-repeat rgba(29, 32, 46, 0.5);
      background-blend-mode: luminosity;

      h3 {
        max-width: 342px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 34px;
      }

      .btn {
        border-radius: 5px;
        background: $loginPanelButtonBG;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    .league {
      background: $panelBG;
      border: 1px solid $panelBorder;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;

      .league_name h4,
      .league_name h3 {
        margin: 0;
      }

      .league_name {
        display: flex;
        flex-direction: row;

        > div {
          display: flex;
          flex-direction: column;
          padding: 9px 0;
          justify-content: center;
        }

        h4 {
          color: #8790ab;
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          margin-bottom: 4;
        }

        h3 {
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      .league_status {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 0;

        .position {
          width: 52px;
          border-radius: 40px;
          min-height: 27px;
          line-height: 27px;
          font-size: 14px;
          color: $dark;
          display: flex;
          justify-content: center;
          text-transform: none;
          font-weight: 500;
          background: $highlightGreen;

          color: $panelBG;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .league_details {
        display: flex;
        flex-direction: column;
        padding: 11px 0;
        align-items: ceter;

        .value {
          font-size: 14px;
          font-weight: 400;
          color: $white;
        }

        .entries {
          color: #8790ab;
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      .league_actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a {
          margin-left: 10px;

          i {
            font-size: 12px;
            color: $white;
            display: inline-block;
            width: 31px;
            height: 31px;
            text-align: center;
            line-height: 31px;
            background: $panelBorder;
            border-radius: 32px;
          }

          &.disabled {
            opacity: 0.25;
          }

          &.link {
            border-left: 1px solid $panelBorder;
            padding-left: 10px;
            margin-left: 10px;
            display: block;
            text-align: center;
            background: none;

            i {
              background: none;
              color: $white;
              font-size: 15px;
            }
          }
        }
      }

      & + .league {
        margin-top: 0.75rem;
      }
    }
  }
}

/**   Dashboard header       **/
.dashboard_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .intro {
    h1 {
      color: $white;
      font-size: 25px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0px 0px 5px 0px;
    }

    h4 {
      color: #8790ab;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .user_level {
    display: flex;
    flex-direction: row;
    flex-direction: flex-end;
    align-items: center;
    text-transform: uppercase;

    .description {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 14px;
      font-weight: 700;

      p {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 3px;

        &:not(:first-child) {
          color: #8790ab;
          font-size: 13px;
          line-height: 13px;
          font-weight: 400;
        }
      }

      .xp-count {
        color: $highlightGreen;
        font-weight: 700;
      }
    }

    .progress {
      width: 106px;
      height: 4px;
      flex-shrink: 0;
      border-radius: 1px;
      background: $panelBorder;
      margin-top: 3px;
    }

    .level {
      text-align: center;
      color: $dark;
      font-weight: 700;
      line-height: 1;
      font-size: 26px;
      margin-left: 1rem;

      &.hexagon {
        background: $highlightGreen;
        &::before {
          border-bottom-color: $highlightGreen;
        }
        &::after {
          border-top-color: $highlightGreen;
        }
      }
    }
  }
}

.dashboard_stats {
  border-radius: 6px;
  border: 1px solid $panelBorder;
  background: linear-gradient(0deg, $panelBorder 0%, #1a1e2e 106.58%);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: 20px 0;

  .stat {
    display: flex;
    flex-direction: column;
    width: 185px;
    position: relative;
    top: 0;
    width: 100%;
    border-right: 1px solid $panelBorder;
    padding: 0px 10px 0px 66px;

    > * {
      color: $white;
      display: block;
    }

    &:last-child {
      border: 0;
    }

    .value {
      font-size: 26px;
      font-weight: 700;
    }

    .label {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      color: $textLight;
      padding-right: 15px;
      white-space: nowrap;
      line-height: 31px;

      &::after {
        display: block;
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        top: 0;
        left: 25px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 100px;
        width: 31px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        font-weight: 900;
        padding: 0;
        color: $white;
        font-size: 14px;
      }
    }

    &.balance,
    &.free {
      .label::after {
        content: "\f154";
      }
    }

    &.leagues {
      .label::after {
        content: "\f091";
      }
    }

    &.xp {
      .label::after {
        content: "XP";
        font-family: "Roboto";
      }
    }
  }
}

.leagues_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  span {
    display: inline-block;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: $panelBorder;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
  }
}

/**   Table filters      **/

.filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  > * {
    margin-left: 1.25rem;
  }
  .btn_group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 0 0 12px;
    border-radius: 100px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

    > *:first-child label {
      border-radius: 20px 0 0 20px;
    }

    > *:last-child label {
      border-radius: 0 20px 20px 0;
    }

    input {
      display: none;
    }

    label {
      background: $panelBG;
      color: $textLight;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0.35rem 0.75rem;
      font-weight: 400;
      margin: 0;
      height: 32px;
      cursor: pointer;
      border: 1px solid $panelBorder;
    }

    input:checked ~ label {
      background: $panelBorder;
      color: $white;
      border: 1px solid $panelBorder;
    }
  }
  a {
    background: $panelBorder;
    border-radius: 30px;
    color: $white;
    font-size: 16px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    display: block;
  }
}

/**   Adverts      **/

.league_crossSell {
  height: 100px;
  background: #004d55;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    color: $white;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
  }
}

/**   aside panel      **/
.dashboard_sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.coming-soon {
  position: relative;

  .super_league_table li > *.username {
    opacity: 1 !important;
  }

  &:before {
    content: "Coming Soon";
    text-align: center;
    color: $white;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#141724, 0.95);
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.aside-panel {
  background: $panelBG;
  border: 1px solid $panelBorder;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  .header,
  .footer {
    display: flex;
    flex-direction: row;
    padding: 0.625rem;
  }

  .header {
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, #2d314a 0%, #22273a 100%);
    border-bottom: 1px solid $panelBorder;
    color: $white;

    h3 {
      color: $white;
      text-transform: uppercase;
      margin: 0 auto 0 10px;

      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .hexagon {
      width: 25px;
      height: 14.43px;
      margin: 7.22px 0;
      text-align: center;
      line-height: 1;
      background: $primary;
      i {
        color: $white;
        position: relative;
        z-index: 20;
        font-size: 16px;
      }
    }

    .hexagon:before,
    .hexagon:after {
      border-left: 12.5px solid transparent;
      border-right: 12.5px solid transparent;
    }

    .hexagon:before {
      border-bottom: 7.22px solid $primary;
    }

    .hexagon:after {
      border-top: 7.22px solid $primary;
    }

    i {
      color: $textLight;
    }
  }

  .body {
    padding: 0.625rem;
  }

  .footer {
    justify-content: space-between;
    align-items: center;

    .text-link {
      color: $textLight;
    }
  }

  &.super_league {
    width: 290px;

    .super_league_table {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        background: #272c41;
        color: $white;
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
        border-radius: 3px;
        justify-content: space-between;

        & + li {
          margin-top: 2px;
        }

        > * {
          padding: 0.35rem 0.25rem;
          display: block;

          &.username {
            opacity: 0.55;
            small {
              font-size: 8px;
            }
          }
        }

        &.my_position {
          border: 1px solid $primary;
        }
      }
    }
  }

  &.activity-log {
    .body {
      height: 200px;
      overflow-y: auto;

      ul {
        margin: 0;
        padding: 0;

        li {
          margin: 0 0 0.5rem;
          padding: 0;
          list-style: none;
          display: flex;

          .activity-icon {
            background: #561eb2;
            width: 26px;
            height: 26px;
            font-size: 12px;
            color: $white;
            text-align: center;
            line-height: 26px;
            border-radius: 30px;
            flex: 0 0 26px;
          }

          .item {
            margin-left: 8px;

            h4 {
              font-size: 15px;
              font-weight: 400;
              margin: 0;
            }

            .time-ago {
              font-size: 11px;
              color: $textLight;
              margin: 0;
            }
          }
        }
      }
    }
  }

  &.side-bets {
    .header {
      .number-circle {
        background: $panelBorder;
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: $white;
        text-align: center;
        line-height: 30px;
        border-radius: 30px;
      }
    }

    .body {
      .bets {
        margin: 0;
        padding: 0;

        .bet {
          list-style: none;
          margin-bottom: 4px;
          padding: 10px;
          background: #272c41;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icon {
            margin-right: 8px;

            span {
              background: #ff2982;
              display: inline-block;
              width: 32px;
              height: 32px;
              text-align: center;
              line-height: 32px;
              border-radius: 30px;

              img {
                height: 24px;
                width: auto;
              }
            }
          }

          .description {
            margin-right: auto;

            h3 {
              margin: 0px;
              font-size: 0.825rem;
            }

            h4 {
              margin: 0;
              font-size: 0.75rem;
              color: $textLight;
            }
          }

          .amount {
            padding-left: 8px;

            span {
              padding: 4px 6px;
              font-weight: 600;

              &.won {
                color: $success;
                display: inline-block;
                position: relative;
                top: 0;
                &::after {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 100%;
                  background-color: $success;
                  opacity: 0.1;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/**   League Detail      **/

.league_detail_inner {
  padding: 1.5rem 2.25rem;

  .league_detail_header {
    height: 110px;
    position: relative;
    top: 0;
    margin-top: 1rem;

    .league_icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 110px;
      height: 110px;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
    }

    .title-row {
      padding-left: 125px;
      height: 56px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title {
        margin-right: 1.25rem;
        flex-grow: 1;
        h3 {
          font-size: 25px;
          font-weight: 500;
          color: $white;
          margin: 0;
        }
        h4 {
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: $textLight;
        }
      }
      .countdown {
        h4 {
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: $textLight;
          text-transform: uppercase;
        }
        .countdown_block {
          span {
            font-size: 18px;
            font-weight: 400;
            color: $white;
          }
        }
      }
    }

    .competition_overview {
      width: 100%;
      height: 54px;
      padding-left: 125px;
      border-radius: 4px;
      border: 1px solid $panelBorder;
      background: linear-gradient(180deg, #2d314a 0%, #22273a 100%);
      display: flex;
      justify-content: flex-start;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

      > div {
        padding: 4px 10px 4px 0;
        max-width: 20%;
        flex: 0 0 20%;

        &:first-child {
          max-width: 15%;
          flex: 0 0 15%;
        }

        &:last-child {
          max-width: 25%;
          flex: 0 0 25%;
        }

        dl {
          margin-bottom: 0;

          dd {
            font-size: 13px;
            font-weight: 400;
            color: $textLight;
            margin: 0 0 2px;
          }

          dt {
            font-weight: 400;
            font-size: 16px;
            color: $white;
          }
        }
      }
    }
  }

  .league-tabs {
    width: 100%;
    border-bottom: 1px solid $highlightGreen;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0;
    padding-right: 125px;
    padding-bottom: 1px;
    margin-top: 25px;

    .nav-tabs {
      width: 100%;

      .nav-item {
        width: 25%;
        button {
          width: 100%;
          border: 1px solid $panelBorder;
          height: 42px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          background: #2a2f45;
          font-weight: 500;
          font-size: 18px;
          color: $white;

          &.active {
            background: $highlightGreen;
            color: $dark;
          }

          &:focus {
            outline: 0;
          }
        }
      }
    }

    .expert {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 100;

      img {
        height: 152px;
        width: auto;
      }
    }
  }
}

.active_leagues.competition {
  h4 {
    color: $textLight;
    margin: 1.75rem 0 0.75rem;
  }

  .active_league {
    display: flex;
    flex-direction: row;
    background: #2a2f45;
    border: 1px solid $highlightBlue;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    color: $white;
    padding: 0.75rem;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: 500;
      font-size: 16px;
      margin: 0;

      span {
        display: block;
        color: $textLight;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .fixtureRow {
    margin-bottom: 9px;
    .fixture-vs {
      border: 1px solid $panelBorder;
      border-radius: 5px;
    }
  }

  .accordion {
    .accordion-item {
      .accordion-header {
        button {
          border: 1px solid $highlightBlue;
          background: $panelBG;
          border-radius: 6px;
          margin-bottom: 9px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px;
          width: 100%;
          color: $white;

          &:focus {
            outline: 0;
          }

          .teamName {
            display: flex;
            align-items: center;
            margin-right: 12px;

            .logo {
              display: inline-block;
              width: 50px;
              margin-right: 12px;
              margin-left: 8px;
              img {
                width: 100%;
                height: auto;
              }
            }

            .name {
              display: inline-block;
              text-align: left;
              font-size: 18px;
              padding-bottom: 5px;
              .position {
                color: $textLight;
                font-size: 14px;
                display: block;
              }
            }
          }

          .stat {
            font-size: 15px;
            padding-right: 12px;
            text-align: left;
            &.lrg {
              font-size: 17px;
            }
            &.highlight {
              color: $highlightBlue;
            }
            .statTitle {
              display: block;
              text-transform: uppercase;
              font-size: 12px !important;
              color: $textLight !important;
              padding-bottom: 3px;
            }
          }

          .btn {
            display: inline-block;
            color: $white;
          }

          &.collapsed {
            border-color: $panelBorder;
            .btn {
              i {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .accordion-body {
        width: 100%;

        .teamPlayer {
          background-position: 12px 100%;
          background-size: contain;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          background-color: $panelBorder;
          margin-bottom: 9px;
          border-radius: 5px;
          padding: 10px;
          padding-left: 125px;

          h3 {
            color: $white;
          }

          .details {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $white;

            .stat {
              font-size: 15px;
              padding-right: 12px;
              text-align: left;
              color: $white;
              line-height: 16px;

              &.lrg {
                font-size: 18px;
              }
              &.highlight {
                color: $highlightBlue;
              }
              .statTitle {
                display: block;
                text-transform: uppercase;
                font-size: 12px !important;
                color: $textLight !important;
                padding-bottom: 3px;
                line-height: 12px;
              }
            }

            .btn {
              background: $highlightBlue;
              color: $white;
              padding: 6px 16px;
              border-radius: 3px;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

/**  Fixture  **/
.fixture-vs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0.75rem;
  background: linear-gradient(180deg, #2d314a 0%, #22273a 100%);
  border-bottom: 1px solid $panelBorder;
  color: $white;
  .team {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
    color: $white;
    flex: 1 1 50%;
    &.teamA {
      justify-content: flex-end;
      text-align: right;
      margin-left: auto;
    }
    &.teamB {
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-right: auto;
    }
    .teamName {
      line-height: 20px;
      height: 48px;
      display: flex;
      align-items: center;
    }
    img {
      max-width: 40px;
      flex: 0 0 40px;
      margin: 0 0.5rem;
    }
  }
  .vs {
    padding: 0 0.75rem;
    color: $textLight;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.fixture-col {
  margin-bottom: 18px;

  .fixture {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $panelBorder;
    background: $panelBG;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

    .fixture-vs {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    .fixture-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem;
      width: 100%;

      h3 {
        font-weight: 500;
        font-size: 16px;
        margin: 0;

        span {
          display: block;
          color: $textLight;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    &.complete {
      border-color: $highlightBlue;
    }
    &.live {
      border: 2px solid $highlightGreen;
      .cta .btn-primary {
        border-color: $highlightGreen;
        background: $highlightGreen;
        color: $dark;
      }
    }
  }
}

/**  Chat Tabs  **/
.chat {
  max-width: 325px;
  flex: 0 0 325px;
  height: calc(100% - 1rem);

  .nav-tabs {
    padding: 0;
    .nav-item {
      width: 50%;
    }
    .nav-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: inline-flex;
      height: 50px;
      width: 100%;
      i {
        font-size: 20px;
        margin-right: 0.75rem;
      }
      &.active {
        border: 0;
      }
    }
  }

  .tab-content {
    padding: 1rem;
    height: calc(100% - 50px);
    background: $panelBG;

    .tab-pane {
      height: 100%;
    }

    .live {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .header {
        margin-bottom: 1rem;

        select {
          border: 1px solid $highlightGreen;
          height: 36px;
        }
      }

      .body {
        overflow-y: auto;

        .message_list {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li.message {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            align-items: flex-end;

            .userProfile {
              width: 28px;
              height: 28px;
              border: 2px solid $highlightBlue;
              background: $highlightBlue;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 24px;
              flex: 0 0 28px;
            }

            .messageBody {
              padding: 0.5rem;
              background: #272c41;
              font-size: 11px;
              border-radius: 5px;
              margin: 0 0.5rem;
              border-bottom-left-radius: 0;
            }

            .time {
              color: #7b7b7b;
              font-size: 8px;
            }

            &.me {
              flex-direction: row-reverse;

              .messageBody {
                background: $panelBorder;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 0;
              }
            }
          }
        }
      }

      .footer {
        margin-top: auto;

        .form-group {
          margin: 0;
          position: relative;
          top: 0;

          input {
            padding-right: 60px;
          }

          a {
            display: inline-block;
            position: absolute;
            top: 8px;

            &.emoticons {
              right: 35px;
            }

            &.send {
              right: 10px;
            }
          }
        }
      }
    }

    .friends {
      .header {
        margin-bottom: 1rem;

        input {
          width: 100%;
          border-radius: 4px;
          border: 1px solid $panelBorder;
          background: #282c44;
          height: 36px;
          line-height: 20px;
          padding: 7px;
          font-size: 14px;
          color: $white;
        }
      }

      .body {
        overflow-y: auto;

        .friend_list {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            align-items: flex-end;

            .userProfile {
              width: 28px;
              height: 28px;
              border: 2px solid $highlightBlue;
              background: $highlightBlue;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 24px;
              flex: 0 0 28px;
            }

            .userDetails {
              display: flex;

              .username {
                display: flex;
                align-items: center;
                line-height: 28px;
                color: $highlightGreen;
                margin-right: 0.5rem;
              }

              .name {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
