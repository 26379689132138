
html {
    height: 100%;
    min-height: 100%;
}

body {
    height: 100%;
    overscroll-behavior: none;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/****  BASE STYLING                  ****/

/**    Fonts                     **/

h1, h2, h3, h4, h5, h6 {
    color: #fff;
}

h1 {
    font-size: 28px;
    font-weight: 800;
    text-align: left;
}

h2 {
    font-size: 22px;
    font-weight: 700;
}

h3 {
    font-size: 18px;
    font-weight: bold;
}

h4 {
    font-size: 15px;
    font-weight: 700;
}

p {
    color: #fff;
    font-size: 13px;
}

ul li {
    color: #fff;
    font-size: 13px;
}

a.text-link {
    color: #fff;
    text-decoration: underline;
    font-size: 13px;
}

/**    Buttons                     **/

.footer_btns {
    margin-top: 0.75rem;
    .btn + .btn {
        margin-left: 0.625rem;
    }
}

.btn {
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.25s ease-in-out;
    i {
        margin-right: 0.75rem;
    }
    &.btn-lg {
        font-size: 15px;
        padding: 1rem 1.5rem;

        @include media-breakpoint-down(sm) {
            font-size: 13px;
            padding: 0.75rem 1.25rem;
        }
    }

    &.btn-md {
        font-size: 13px;
        padding: 0.5rem 1.25rem;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            padding: 0.5rem 1rem;
        }
    }

    &.btn-sm {
        font-size: 11px;
        padding: 0.25rem 1rem;

        @include media-breakpoint-down(sm) {
            font-size: 10px;
            padding: 0.15rem 0.75rem;
        }
    }

    &.btn-secondary {
        background: rgba(#fff, 0.85);
        color: #0B0B0B;
        border: 1px solid transparent;

        &:focus {
            background: rgba(#fff, 1);
            border: 1px solid #fff;
            box-shadow: 0 0 0 0.2rem rgba(255,255,255,.5);
            color: #0B0B0B;
        }
    }

    &.btn-secondary:not(:disabled):not(.disabled).active, 
    &.btn-secondary:not(:disabled):not(.disabled):active {
        background: rgba(#fff, 1);
        border: 1px solid #fff;
        color: #0B0B0B;
    }
}